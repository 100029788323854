#toppart_wrap {
  background-image: url("./img/round_v_2.png");
  background-position: 280px -200px;
  background-size: 75%;
  background-repeat: no-repeat;
  width: 100%;
  height: 120vh;
  margin-bottom: -40px;
}

.button1 {
  text-align: center;
}

/*------ circle animation ------- */

.circle-animation {
  display: flex;
  text-align: center;
  justify-content: center;
  min-height: 0;
  margin-left: 620px;
}

.ring {
  position: absolute;
  width: 350px;
  height: 350px;
  margin-top: -270px;
  margin-right: -80px;
  border-radius: 50%;
  animation: ring 3s linear infinite;
}

@keyframes ring {
  0% {
    transform: rotate(0deg);
    box-shadow: 1px 3px 1px white;
  }
  100% {
    transform: rotate(360deg);
    box-shadow: 1px 3px 1px white;
  }
}

.round img {
  height: 380px;
  width: 380px;
  margin-left: 99%;
  margin-top: -70%;
}

.model img {
  height: 530px;
  width: 630px;
  margin-left: -78%;
  margin-top: -55%;
  position: relative;
}
.cardid {
  margin-top: -94px;
  background-image: url("./img/bg_3.png");
  background-size: 100%;
  background-position: center bottom;
  height: 100vh;
  background-repeat: no-repeat;
}
#type-text {
  margin-top: 14%;
}

/* --------------media query for responsive------------------------------- */

@media only screen and (max-width: 5200px) and (min-width: 3901px) {
  #toppart_wrap {
    margin-left: 220px;
    margin-top: -300px;
    background-image: url("./img/round_v_2.png");
    background-position: 1900px 80px;
    background-size: 20%;
    background-repeat: no-repeat;
    width: 100%;
    height: 120vh;
  }
}

@media only screen and (max-width: 3900px) and (min-width: 3101px) {
  #toppart_wrap {
    margin-left: 220px;
    margin-top: -300px;
    background-image: url("./img/round_v_2.png");
    background-position: 1360px 80px;
    background-size: 25%;
    background-repeat: no-repeat;
    width: 100%;
    height: 120vh;
  }
}
@media only screen and (max-width: 3100px) and (min-width: 2801px) {
  #toppart_wrap {
    margin-left: 220px;
    margin-top: -300px;
    background-image: url("./img/round_v_2.png");
    background-position: 860px 80px;
    background-size: 34%;
    background-repeat: no-repeat;
    width: 100%;
    height: 120vh;
  }
}

@media only screen and (max-width: 2800px) and (min-width: 2601px) {
  #toppart_wrap {
    margin-left: 220px;
    margin-top: -300px;
    background-image: url("./img/round_v_2.png");
    background-position: 740px 80px;
    background-size: 37%;
    background-repeat: no-repeat;
    width: 100%;
    height: 120vh;
  }
}

@media only screen and (max-width: 2600px) and (min-width: 2501px) {
  #toppart_wrap {
    margin-left: 220px;
    margin-top: -300px;
    background-image: url("./img/round_v_2.png");
    background-position: 660px 40px;
    background-size: 40%;
    background-repeat: no-repeat;
    width: 100%;
    height: 120vh;
  }
}
@media only screen and (max-width: 2500px) and (min-width: 2301px) {
  #toppart_wrap {
    margin-left: 220px;
    background-image: url("./img/round_v_2.png");
    background-position: 780px -200px;
    background-size: 40%;
    background-repeat: no-repeat;
    width: 100%;
    height: 120vh;
  }
}

@media only screen and (max-width: 2300px) and (min-width: 2101px) {
  #toppart_wrap {
    background-image: url("./img/round_v_2.png");
    background-position: 540px -200px;
    background-size: 51%;
    background-repeat: no-repeat;
    width: 100%;
    height: 120vh;
  }
}

@media only screen and (max-width: 2100px) and (min-width: 1901px) {
  #toppart_wrap {
    background-image: url("./img/round_v_2.png");
    background-position: 540px -200px;
    background-size: 51%;
    background-repeat: no-repeat;
    width: 100%;
    height: 120vh;
  }
}

@media only screen and (max-width: 1900px) and (min-width: 1701px) {
  #toppart_wrap {
    background-image: url("./img/round_v_2.png");
    background-position: 440px -200px;
    background-size: 57%;
    background-repeat: no-repeat;
    width: 100%;
    height: 120vh;
  }
}

@media only screen and (max-width: 1700px) and (min-width: 1501px) {
  #toppart_wrap {
    background-image: url("./img/round_v_2.png");
    background-position: 400px -200px;
    background-size: 60%;
    background-repeat: no-repeat;
    width: 100%;
    height: 120vh;
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1301px) {
  #toppart_wrap {
    background-image: url("./img/round_v_2.png");
    background-position: 275px -200px;
    background-size: 70%;
    background-repeat: no-repeat;
    width: 100%;
    height: 120vh;
  }
}

@media only screen and (max-width: 1199px) and (min-width: 981px) {
  #toppart_wrap {
    margin-top: -35px;
    background-image: url("./img/round_v_2.png");
    background-position: 275px -200px;
    background-size: 75%;
    background-repeat: no-repeat;
    width: 100%;
    height: 120vh;
  }

  .ring {
    position: absolute;
    width: 350px;
    height: 350px;
    margin-top: -270px;
    margin-right: 115px;
    border-radius: 50%;
    animation: ring 3s linear infinite;
  }

  @keyframes ring {
    0% {
      transform: rotate(0deg);
      box-shadow: 1px 3px 1px white;
    }
    100% {
      transform: rotate(360deg);
      box-shadow: 1px 3px 1px white;
    }
  }

  .round img {
    height: 380px;
    width: 380px;
    margin-left: 74%;
    margin-top: -70%;
  }

  .model img {
    height: 530px;
    width: 630px;
    margin-left: -109%;
    margin-top: -55%;
    position: relative;
  }

  .button1 {
    width: 150px;
  }
}
@media screen and (max-width: 980px) {
  #toppart_wrap {
    /* overflow: hidden; */
    background-image: url("./img/round_v_2.png");
    background-position: 280px -200px;
    background-size: 75%;
    background-repeat: no-repeat;
    width: 100%;
    height: 120vh;
  }

  .circle-animation {
    display: flex;
    text-align: center;
    justify-content: center;
    min-height: 0;
    /* margin-left: 620px; */
  }

  .ring {
    position: absolute;
    width: 300px;
    height: 300px;
    margin-top: -270px;
    margin-right: 121%;
    border-radius: 50%;
    animation: ring 3s linear infinite;
  }

  @keyframes ring {
    0% {
      transform: rotate(0deg);
      box-shadow: 1px 3px 1px white;
    }
    100% {
      transform: rotate(360deg);
      box-shadow: 1px 3px 1px white;
    }
  }

  .round img {
    height: 300px;
    width: 300px;
    margin-left: 15% !important;
    margin-top: -88% !important;
  }

  .model img {
    height: 400px;
    width: 430px;
    margin-left: -195%;
    margin-top: -74%;
    position: relative;
  }

  #type-text {
    margin-top: 20px;
  }
}
/* -----------------------------for mobile device--------------------------------- */
@media screen and (max-width: 575px) {
  #toppart_wrap {
    margin-bottom: -35px;
    overflow: hidden;
    height: 700px;
    background-position: -162px -80px;
    background-size: 180%;
  }

  .circle-animation {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    min-height: 0;
    margin-left: 25px;
    margin-top: 20px;
  }

  .ring {
    position: absolute;
    width: 240px;
    height: 240px;
    border-radius: 50%;
    margin-top: 0px;
    margin-left: 155%;
    animation: ring 3s linear infinite;
  }

  @keyframes ring {
    0% {
      transform: rotate(0deg);
      box-shadow: 1px 3px 1px white;
    }
    100% {
      transform: rotate(360deg);
      box-shadow: 1px 3px 1px white;
    }
  }
  .model img {
    height: 350px;
    width: 400px;
    position: relative;
    margin-top: 28px;
    margin-right: -142px;
  }

  .round img {
    height: 260px;
    width: 260px;
    margin-left: 64% !important;
    margin-top: 10% !important;
  }
  #we-do,
  #we-doI {
    font-size: 30px !important;
  }
}

/* --------------------for mobile device-------------------- */
@media screen and (max-width: 410px) {
  #toppart_wrap {
    overflow: hidden;
    margin-bottom: 25px;
    height: 643px;
    background-size: 170%;
    background-color: #c6da9d;
    background-position: -220px 137px;
    margin-bottom: 80px;
  }

  .circle-animation {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    min-height: 0;
    margin-left: 25px;
    margin-top: 20px;
  }

  .ring {
    position: absolute;
    width: 240px;
    height: 240px;
    border-radius: 50%;
    margin-top: 0px;
    margin-left: 128%;
    animation: ring 3s linear infinite;
  }

  @keyframes ring {
    0% {
      transform: rotate(0deg);
      box-shadow: 1px 3px 1px white;
    }
    100% {
      transform: rotate(360deg);
      box-shadow: 1px 3px 1px white;
    }
  }
  .model img {
    height: 350px;
    width: 400px;
    position: relative;
    margin-top: 28px;
    margin-right: -142px;
  }

  .round img {
    height: 260px;
    width: 260px;
    margin-left: 64% !important;
    margin-top: 10% !important;
  }

  #we-do,
  #we-doI {
    font-size: 30px !important;
  }
}
